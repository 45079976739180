import request from '@/utils/request'

//获取教室列表
export function getClassroomList(params) {
    return request.get('/api-school/classroom', params)
}

//新增或修改教室
export function classroomAddOrUpdate(params) {
    return request.post('/api-school/classroom/saveOrUpdate', params)
}

//获取教室详情
export function getClassroom(id) {
    return request.get('/api-school/classroom/'+id)
}

//删除教室
export function classroomRemove(params) {
    return request.remove('/api-school/classroom', params)
}

//获取教室设备列表
export function getDeviceList(params) {
    return request.get('/api-device/device/list/by-classroom-id',params)
}

//教室解绑设备
export function classroomUnbindDevice(params) {
    return request.post('/api-device/device/unbind', params)
}