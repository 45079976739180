<template>
  <layout-content :total="total">
    <!-- 面包屑的插槽 -->
    <breadcrumb slot="breadcrumb"></breadcrumb>
    <div slot="back" class="back" @click="back">返回</div>
    <div slot="content">
      <!-- 搜索和新增 -->
      <div class="d-f a-c j-b">
        <a-form-model ref="queryForm" :model="queryParams" layout="inline">
          <a-form-model-item label="教室名称" prop="name">
            <a-input
              v-model="queryParams.name"
              placeholder="请输入教室名称"
              allowClear
              @keyup.enter.native="handleQuery"
            />
          </a-form-model-item>
          <a-form-model-item>
            <a-button
              type="primary"
              @click="handleQuery"
              v-hasPermi="['basicInfo:classroom:query']"
              >搜索</a-button
            >
            <a-button
              @click="resetQuery"
              class="ml10"
              v-hasPermi="['basicInfo:classroom:query']"
            >
              重置
            </a-button>
          </a-form-model-item>
        </a-form-model>
        <div>
          <a-button
            type="primary"
            @click="handleAdd"
            icon="plus"
            v-hasPermi="['basicInfo:classroom:add']"
            >新增</a-button
          >
        </div>
      </div>
      <!--表格-->
      <a-table
        :columns="columns"
        rowKey="id"
        :data-source="data"
        :pagination="false"
        class="mt20"
      >
        <span slot="name" slot-scope="text" :title="text">
          <span>{{ text }}</span>
        </span>
        <span slot="action" slot-scope="text, record">
          <a @click="handleUpdate(record)" v-hasPermi="['basicInfo:classroom:edit']"
            >编辑</a
          >
          <a-divider type="vertical" />
          <a @click="handleDelete(record)" v-hasPermi="['basicInfo:classroom:remove']"
            >删除</a
          >
        </span>
      </a-table>
      <!--新增和编辑的弹出框-->
      <a-modal
        v-model="visible"
        :title="title"
        @ok="submit"
        @cancel="handleCancel"
        width="500px"
      >
        <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-row>
            <a-col :span="24">
              <a-form-model-item label="教室名称" prop="name">
                <a-input v-model="form.name" placeholder="请输入教室名称" />
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </a-modal>
    </div>
  </layout-content>
</template>

<script>
import LayoutContent from "@/views/layout/content";
import Breadcrumb from "~c/Breadcrumb";
import {
  getClassroomList,
  classroomAddOrUpdate,
  classroomRemove,
  getClassroom,
} from "@/api/basic-info/classroom.js";
const columns = [
  {
    title: "教室名称",
    dataIndex: "name",
    align: "center",
  },
  {
    title: "操作",
    dataIndex: "action",
    // width: "25%",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];
export default {
  name: "BuildingData",
  components: { LayoutContent, Breadcrumb },
  data() {
    return {
      total: null,
      // 查询参数
      queryParams: {
        //当前页
        page: 1,
        //一页几个
        limit: 10,
        //楼栋id
        builingId: null,
      },
      //表格的表头文字
      columns,
      //表格的数据
      data: [],
      //新增和编辑的表单
      form: {},
      //表单规则
      rules: {
        name: [
          {
            required: true,
            message: "请输入教室名称",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
      },
      //label和wrapper的占位
      labelCol: { span: 5 },
      wrapperCol: { span: 19 },
      //新增和编辑弹出框的标题
      title: "",
      //新增和编辑弹出框的显示隐藏
      visible: false,
    };
  },
  methods: {
    //返回上一级
    back() {
      this.$router.go(-1);
    },
    //获取教室列表
    getClassroomList() {
      getClassroomList(this.queryParams).then((res) => {
        this.data = res.data;
        this.total = res.count;
      });
    },
    //搜索
    handleQuery() {
      this.queryParams.page = 1;
      this.getClassroomList();
    },
    //搜索重置
    resetQuery() {
      this.queryParams.page = 1;
      this.$refs.queryForm.resetFields();
      this.getClassroomList();
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.form = {};
      this.form.buildingId = this.$route.query.buildingId;
      this.visible = true;
      this.title = "新增教室";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.form = {};
      getClassroom(row.id).then((res) => {
        this.form = res.data;
        this.visible = true;
        this.title = "修改教室";
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      this.$confirm({
        title: "您确认删除您选中的数据吗？",
        onOk: () => {
          let arr = [];
          arr.push(row.id);
          classroomRemove(arr).then((res) => {
            this.$message.success("删除成功");
            this.getClassroomList();
          });
        },
      });
    },
    //新增或编辑确定
    submit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          classroomAddOrUpdate(this.form).then((res) => {
            //修改
            if (this.form.id) {
              this.$message.success("修改成功");
            }
            //新增
            else {
              this.$message.success("新增成功");
            }
            this.visible = false;
            this.getClassroomList();
          });
        }
      });
    },
    //新增或编辑取消
    handleCancel() {
      this.$refs.ruleForm.resetFields();
      this.visible = false;
    },
  },
  created() {
    this.queryParams.buildingId = this.$route.query.buildingId;
    this.getClassroomList();
  },
};
</script>

<style scoped lang="less">
.back {
  color: @primary-color;
  font-weight: normal;
  cursor: pointer;
}
</style>
